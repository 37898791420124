import gql from 'graphql-tag';

export default gql`
mutation insertMicrosoftCRMContacts($obj: insertMicrosoftCRMContactsInput!) {
  insertMicrosoftCRMContacts(object: $obj) {
    marketingListId
    personalizedVariableSetId
  }
}
`;
