<template>
  <div>
    <b-container v-if="showTabs">
      <b-tabs
          vertical
          nav-wrapper-class="col-3"
          nav-class="mt-5"
          v-model="tabIndex"
          @input="scrollToTop"
      >
        <template #tabs-start>
          <b-button
              v-if="allowedToCreatePersonalizationSets"
              @click="showNewPersonalizationSetModal = true"
              class="green-button-transparent mr-3 border-0 mb-4">
            <b-icon
                icon="plus-circle-fill"
                class="mr-2 top--3"
                aria-hidden="true"
                scale="1"
            ></b-icon>
            {{ $t('buttons.createPersonalization') }}
          </b-button>
          <modal-create-new-personalization-set
              v-if="showNewPersonalizationSetModal"
              :is-sys-admin="isSysAdmin"
              @create-personalization-set="createPersonalizationSet"
              @close-create-personalization-set-modal="showNewPersonalizationSetModal = false"
          />
        </template>
        <template #empty>
          <div class="mt-5" v-if="allowedToCreatePersonalizationSets">
            {{ $t('settings.personalization.howToPersonalizationDesc') }}
          </div>
          <div
            class="mt-5"
            v-else
            v-html="$t('settings.personalization.notAllowedToCreatePersonalization')"
          >
          </div>
        </template>
        <b-tab
            v-for="(personalizationSet, index) in personalizationSetsComp"
            :key="'personalizationTab_' + index"
            lazy
        >
          <template v-slot:title>
            {{ personalizationSet.name }}
          </template>
          <personalization-list-view
              v-if="personalizationSet"
              class="mt-5"
              :key="listViewRenderKey"
              :personalization-set="personalizationSet"
              :personalization-set-index="index"
              :user-id="userId"
              @edit-personalization-set="editPersonalizationSet"
              @delete-personalization-set="deletePersonalizationSet"
              @reimport-contacts="reimportContacts"
              @update-schema-in-personalization-set="updateSchemaInPersonalizationSet"
          />
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import SetPageTypeInCache from '@/graphQlQueries/mutations/setPageTypeInCache';
import SetShowHeaderInCache from '@/graphQlQueries/mutations/setShowHeaderInCache';
import GetPersonalizedVariableSets from '@/graphQlQueries/queries/getPersonalizedVariableSets';
import GetAllPersonalizationGroups from '@/graphQlQueries/queries/getAllPersonalizationGroupsForAtLeastManager';
import CreatePersonalizationSet from '@/graphQlQueries/mutations/createPersonalizationSet';
import InsertMicrosoftCRMContacts from '@/graphQlQueries/mutations/insertMicrosoftCRMContacts';
import DeletePersonalizationSet from '@/graphQlQueries/mutations/deletePersonalizationSet';
import UpdatePersonalizedVariableSet from '@/graphQlQueries/mutations/updatePersonalizedVariableSet';
import Auth from '@aws-amplify/auth';
import { cloneDeep } from 'lodash';

export default {
  name: 'Personalization',
  components: {
    ModalCreateNewPersonalizationSet: () => import('@/components/modals/personalization/ModalCreateNewPersonalizationSet.vue'),
    PersonalizationListView: () => import('@/components/settings/personalization/PersonalizationListView.vue'),
  },
  data() {
    return {
      showTabs: false,
      userId: null,
      isSysAdmin: null,
      pageType: 'personalization-settings',
      tabIndex: 0,
      listViewRenderKey: 0,
      personalizedVariableSets: null,
      personalizationSets: [],
      rerenderKey: 0,
      currentGroupPrivileges: [],
      newOrChangedSetId: null,
      deletedSetId: null,
      number: 0,
      showNewPersonalizationSetModal: false,
      allowedToCreatePersonalizationSets: null,
    };
  },
  computed: {
    personalizationSetsComp: {
      cache: false,
      get() {
        return this.personalizationSets;
      },
    },
  },
  apollo: {
    allowedToCreatePersonalizationSets: {
      variables() {
        return {
          userId: this.userId,
        };
      },
      query: GetAllPersonalizationGroups,
      update(data) {
        if (data.users_by_pk?.sys_admin) {
          return true;
        }
        return !!data.users_roles_groups.length;
      },
      skip() {
        return !this.userId;
      },
    },
    personalizedVariableSets: {
      query: GetPersonalizedVariableSets,
      update(data) {
        console.log('personalization raw data', data);
        if (data.getPersonalizedVariableSets) {
          this.personalizationSets = this.sortPersonalizationSet(
            cloneDeep(data.getPersonalizedVariableSets),
          );
          if (this.newOrChangedSetId !== null) {
            const insertedOrUpdatedSetIndex = this.personalizationSets.findIndex(
              (set) => set.personalized_variable_set_id === this.newOrChangedSetId,
            );
            if (insertedOrUpdatedSetIndex !== -1) {
              this.tabIndex = insertedOrUpdatedSetIndex;
              this.newOrChangedSetId = null;
            }
          }
          if (this.deletedSetId !== null) {
            this.tabIndex = 0;
            this.deletedSetId = null;
          }
          this.showTabs = true;
          console.warn('personalization data', this.tabIndex, this.personalizationSets);
          return data.getPersonalizedVariableSets;
        }
        this.showTabs = true;
        return [];
      },
      fetchPolicy: 'network-only',
    },
  },
  async created() {
    const currentSession = await Auth.currentSession();
    const idTokenPayload = JSON.parse(currentSession.getIdToken().payload['https://hasura.io/jwt/claims']);
    this.isSysAdmin = idTokenPayload['x-hasura-allowed-roles'].includes('admin');
    this.userId = (idTokenPayload['x-hasura-user-id'])
      ? Math.floor(idTokenPayload['x-hasura-user-id'])
      : null;
    if (this.userId) {
      // check if the user is admin
      if (idTokenPayload['x-hasura-allowed-roles'].includes('admin')) {
        this.$currentUserRole = 'admin';
      } else {
        this.$currentUserRole = 'user';
      }
    }
    await this.$apollo.mutate({
      mutation: SetPageTypeInCache,
      variables: {
        type: this.pageType,
      },
    });
    // this mutation is used to display the header of the application
    await this.$apollo.mutate({
      mutation: SetShowHeaderInCache,
      variables: {
        show: true,
      },
    });
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async createPersonalizationSet(personalizationSet) {
      try {
        if (this.userId) {
          // update the database
          // set the type of the set
          // 1: 'manual'
          // 2: 'Microsoft Dynamics'
          let set_type = 1;
          if (personalizationSet.microsoftImport) {
            set_type = 2;
          }
          const {
            data: {
              insertPersonalizedVariableSet: {
                newPersonalizedVariableSetId: insertedSetId,
              },
            },
          } = await this.$apollo.mutate({
            mutation: CreatePersonalizationSet,
            variables: {
              obj: {
                name: personalizationSet.name,
                description: personalizationSet.description,
                set_type,
              },
            },
          });
          this.newOrChangedSetId = insertedSetId;
          await this.createSchemaAndContactsForMicrosoftCRM(personalizationSet);
          this.showTabs = false;
          await this.$apollo.queries.personalizedVariableSets.refresh();
        } else {
          throw new Error('User not logged in');
        }
      } catch (e) {
        console.error('Error creating personalization set', e);
      }
      this.showNewPersonalizationSetModal = false;
    },
    async reimportContacts(personalizationSet) {
      this.newOrChangedSetId = personalizationSet.setId;
      try {
        await this.createSchemaAndContactsForMicrosoftCRM(personalizationSet);
        this.showTabs = false;
        await this.$apollo.queries.personalizedVariableSets.refresh();
      } catch (e) {
        console.error('Error reimporting contacts', e);
      }
    },
    createSchemaAndContactsForMicrosoftCRM(personalizationSet) {
      return new Promise((resolve) => {
        if (personalizationSet.microsoftImport) {
          const selectedContactFields = personalizationSet.microsoftImport.contactFields;
          const schema = selectedContactFields.map((fieldKey) => {
            const { fieldType } = personalizationSet.microsoftImport.allCRMContactFields
              .find((field) => field.fieldName === fieldKey);
            return {
              id: fieldKey,
              name: fieldKey,
              type: fieldType,
            };
          });
          this.$apollo.mutate({
            mutation: UpdatePersonalizedVariableSet,
            variables: {
              obj: {
                personalized_variable_set_id: this.newOrChangedSetId,
                name: personalizationSet.name,
                schema: JSON.stringify(schema),
              },
            },
          }).then(() => {
            // last import the contacts
            if (personalizationSet.microsoftImport.marketingListId) {
              this.$apollo.mutate({
                mutation: InsertMicrosoftCRMContacts,
                variables: {
                  obj: {
                    personalizedVariableSetId: this.newOrChangedSetId,
                    marketingListId: personalizationSet.microsoftImport.marketingListId,
                  },
                },
              }).then(() => {
                this.$apollo.mutate({
                  mutation: SetLoadingIndicator,
                  variables: {
                    isIndicatorLoading: false,
                  },
                });
                resolve(true);
              });
            }
          });
        } else {
          resolve(true);
        }
      });
    },
    sortPersonalizationSet(persoSet) {
      // sort the personalization sets by name
      persoSet.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
      return persoSet;
    },
    async editPersonalizationSet(personalizationSet, setId, schema) {
      try {
        const updateObject = {
          personalized_variable_set_id: setId,
          name: personalizationSet.name,
          description: personalizationSet.description,
          schema: JSON.stringify(schema),
        };
        await this.$apollo.mutate({
          mutation: UpdatePersonalizedVariableSet,
          variables: {
            obj: updateObject,
          },
        });
        this.newOrChangedSetId = setId;
        await this.$apollo.queries.personalizedVariableSets.refresh();
        // update the list view component
        this.listViewRenderKey++;
      } catch (e) {
        console.error('Error updating personalization set', e);
      }
      this.rerenderKey += 10000;
    },
    async deletePersonalizationSet(setId) {
      try {
        await this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: true,
          },
        });
        const {
          data: {
            deletePersonalizedVariableSet: {
              deletedPersonalizedVariableSetId: deleteItemSetId,
            },
          },
        } = await this.$apollo.mutate({
          mutation: DeletePersonalizationSet,
          variables: {
            id: setId,
          },
        });
        this.deletedSetId = deleteItemSetId;
        this.showTabs = false;
        await this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
        await this.$apollo.queries.personalizedVariableSets.refresh();
      } catch (e) {
        console.error('Error deleting personalization set', e);
      }
      this.rerenderKey += 10000;
    },
    updateSchemaInPersonalizationSet() {
      this.$apollo.queries.personalizedVariableSets.refresh();
    },
  },
};

</script>

<style scoped lang="scss">
/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 5px;

  &:hover {
    color: #fff;
  }
}
</style>
