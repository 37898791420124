import gql from 'graphql-tag';

export default gql`
query getPersonalizedVariableSets {
  getPersonalizedVariableSets {
    personalized_variable_set_id
    name
    description
    schema
    set_type
  }
}
`;
