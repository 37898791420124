import gql from 'graphql-tag';

export default gql`
mutation deletePersonalizationSet($id: Int!) {
  deletePersonalizedVariableSet(
    object: {
      personalized_variable_set_id: $id
    }
  ) {
    deletedPersonalizedVariableSetId
  }
}
`;
