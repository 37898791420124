var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showTabs
        ? _c(
            "b-container",
            [
              _c(
                "b-tabs",
                {
                  attrs: {
                    vertical: "",
                    "nav-wrapper-class": "col-3",
                    "nav-class": "mt-5"
                  },
                  on: { input: _vm.scrollToTop },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "tabs-start",
                        fn: function() {
                          return [
                            _vm.allowedToCreatePersonalizationSets
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "green-button-transparent mr-3 border-0 mb-4",
                                    on: {
                                      click: function($event) {
                                        _vm.showNewPersonalizationSetModal = true
                                      }
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2 top--3",
                                      attrs: {
                                        icon: "plus-circle-fill",
                                        "aria-hidden": "true",
                                        scale: "1"
                                      }
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "buttons.createPersonalization"
                                          )
                                        ) +
                                        " "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.showNewPersonalizationSetModal
                              ? _c("modal-create-new-personalization-set", {
                                  attrs: { "is-sys-admin": _vm.isSysAdmin },
                                  on: {
                                    "create-personalization-set":
                                      _vm.createPersonalizationSet,
                                    "close-create-personalization-set-modal": function(
                                      $event
                                    ) {
                                      _vm.showNewPersonalizationSetModal = false
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "empty",
                        fn: function() {
                          return [
                            _vm.allowedToCreatePersonalizationSets
                              ? _c("div", { staticClass: "mt-5" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "settings.personalization.howToPersonalizationDesc"
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              : _c("div", {
                                  staticClass: "mt-5",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "settings.personalization.notAllowedToCreatePersonalization"
                                      )
                                    )
                                  }
                                })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    1371061285
                  ),
                  model: {
                    value: _vm.tabIndex,
                    callback: function($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex"
                  }
                },
                _vm._l(_vm.personalizationSetsComp, function(
                  personalizationSet,
                  index
                ) {
                  return _c(
                    "b-tab",
                    {
                      key: "personalizationTab_" + index,
                      attrs: { lazy: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(
                                  " " + _vm._s(personalizationSet.name) + " "
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      personalizationSet
                        ? _c("personalization-list-view", {
                            key: _vm.listViewRenderKey,
                            staticClass: "mt-5",
                            attrs: {
                              "personalization-set": personalizationSet,
                              "personalization-set-index": index,
                              "user-id": _vm.userId
                            },
                            on: {
                              "edit-personalization-set":
                                _vm.editPersonalizationSet,
                              "delete-personalization-set":
                                _vm.deletePersonalizationSet,
                              "reimport-contacts": _vm.reimportContacts,
                              "update-schema-in-personalization-set":
                                _vm.updateSchemaInPersonalizationSet
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }